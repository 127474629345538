<template>
  <div class="">
    <div class="flex justify-between items-center mb-6">
      <div>
        <h1 class="text-4xl">Staffs</h1>
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>

      <div class="flex">
        <div>
          <input
            class="p-2 border w-80 rounded"
            v-model="filter"
            @keyup="search()"
            type="search"
            placeholder="Search"
          />
        </div>
        <button @click="rqFilter()" class="mx-2">
          <span>
            <i
              :class="{ 'text-blue-600': onFilter }"
              class="bx bx-filter-alt text-2xl"
            ></i>
          </span>
        </button>
      </div>

      <router-link
        to="/staffs/create"
        class="border px-6 py-2 rounded-lg text-sm flex justify-center items-center hover:bg-gray-100"
      >
        Add New Staff
        <i class="bx bxs-plus-circle pl-1"></i>
      </router-link>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <div v-if="onFilter" class="flex grid-flow-col gap-4 bg-gray-50 p-3">
              <div class="flex flex-col w-80">
                <label class="text-gray-600" for="branch">Filter by staff </label>
                <select
                  class="p-2 border rounded"
                  name="branch"
                  @change="loadData(1, filter)"
                  v-model="filterStaff"
                >
                  <option value="" disabled selected>Filter by staff</option>
                  <option value="">All</option>
                  <option v-for="(staff, i) in FilterStaffs" :key="i" :value="staff.id">
                    {{ staff.name }}
                  </option>
                </select>
              </div>

              <div class="flex flex-col w-80">
                <label class="text-gray-600" for="branch">Filter by branch </label>
                <select
                  class="p-2 border rounded"
                  name="branch"
                  @change="loadData(1, filter)"
                  v-model="filterBranch"
                >
                  <option value="" disabled selected>Filter by branch</option>
                  <option v-for="(branch, i) in branches" :key="i" :value="branch.id">
                    {{ branch.name }}
                  </option>
                </select>
              </div>
            </div>
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name / Contact Info
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Branch
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Total Shortage
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only"></span>
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only"></span>
                  </th>
                </tr>
              </thead>
              <tbody v-if="staffs" class="bg-white divide-y divide-gray-200">
                <tr v-for="(staff, i) in staffs.data" :key="i">
                  <td class="px-3 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ staff.first_name }} {{ staff.last_name }}
                        </div>
                        <div class="text-sm text-gray-500">
                          {{ staff.email }} / {{ staff.contact_phone }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="px-3 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div
                          v-if="staff.branch"
                          class="text-sm font-medium text-gray-900"
                        >
                          {{ staff.branch }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="px-3 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ formatPrice(staff.total_shortage) }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <span
                      v-if="staff.status"
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                    >
                      Active
                    </span>

                    <span
                      v-else
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800"
                    >
                      In-Active
                    </span>
                  </td>

                  <td class="text-right">
                    <span
                      v-if="staff.isOnline"
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                    >
                      Online
                    </span>

                    <span
                      v-else
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
                    >
                      Offline
                    </span>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                    <div class="flex justify-end">
                      <button
                        @click="onDropDown(i)"
                        class="relative z-10 block rounded-md bg-white text-xl p-2 focus:outline-none"
                      >
                        <i class="bx bx-dots-vertical-rounded"></i>
                      </button>
                    </div>

                    <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                      <div
                        v-show="dropdownOpen.isOpen && dropdownOpen.index == i"
                        @click="onDropDown(i)"
                        class="absolute right-10 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20"
                      >
                        <router-link
                          :to="`/staffs/${staff.id}/edit`"
                          class="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white"
                          >Edit</router-link
                        >
                        <router-link
                          :to="`/staffs/${staff.id}`"
                          class="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white"
                          >View</router-link
                        >

                        <button
                          v-if="staff.id != 1"
                          @click="showConfirmModal(staff, 'deactivate')"
                          class="block px-4 w-full text-left py-2 font-bold text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white"
                        >
                          <span v-if="staff.status"> Deactivate </span>
                          <span v-else> Activate </span>
                        </button>

                        <button
                          v-if="staff.id != 1"
                          @click="showConfirmModal(staff, 'password')"
                          class="block px-4 font-bold w-full py-2 text-left text-sm text-gray-700 capitalize hover:bg-blue-500 hover:text-white"
                        >
                          Reset Password
                        </button>
                      </div>
                    </transition>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>

            <div class="border-t" v-if="staffs">
              <pagination :data="staffs" :midSize="2" @clicked="loadData"> </pagination>
            </div>

            <confimation
              v-show="isConfirmModalVisible"
              modalHeadline="Please confirm?"
              :isLoading="isLoading"
              :confirmMessage="getMessage()"
              @confirmEvent="confirmRequest"
              @close="closeConfirmModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "../../../components/Pagination";
import Confimation from "../../../components/Confimation.vue";
import Swal from "sweetalert2";

import { mapGetters } from "vuex";
import checkPermissionExist from "../../../mixins/checkPermissionExist";

export default {
  middleware: "auth",
  components: { Pagination, Confimation },

  mixins: [checkPermissionExist],
  computed: mapGetters({
    user: "auth/user",
  }),

  layout: "default",

  data: () => ({
    staffs: [],
    selectedStaff: null,
    isLoading: false,
    isConfirmModalVisible: false,

    filter: "",
    onFilter: false,

    filterStaff: "",
    FilterStaffs: [],
    filterDate: "",
    filterBranch: "",
    branches: [],

    dropdownOpen: {
      isOpen: false,
      index: null,
    },
    userHasConfirmed: false,
    modalType: "",
  }),

  created() {
    var _this = this;
    _this.loadData(1);
    if (!this.checkPermissionExist(this.user.data, "staffs-read")) {
      this.$router.go(-1);
      return false;
    }
  },

  methods: {
    search() {
      // var vm = this;
      this.searching = true;
      this.loadData(1, this.filter);
    },

    rqFilter() {
      this.onFilter = !this.onFilter;

      if (this.onFilter) {
        this.loadBranches();
        this.loadStaffs();
      }
    },

    async loadBranches() {
      // Submit the form

      try {
        const { data } = await axios.get(`/get/branches`);

        this.branches = data.data;

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;
        return false;
      }
    },

    async loadStaffs() {
      // Submit the form

      try {
        const { data } = await axios.get(`/staff/all`);

        this.FilterStaffs = data.data;

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;
        return false;
      }
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    async loadData(pageNo, filter) {
      if (filter) {
        this.searching = true;
      } else {
        filter = "";
        this.searching = false;
      }

      try {
        const { data } = await axios.get(
          `/staffs?page=${pageNo}&filter=${filter}&branch_id=${this.filterBranch}&staff_id=${this.filterStaff}`
        );

        this.staffs = data;
        this.searching = false;

        if (this.filterBranch || this.filterStaff) {
          let temp = [];
          this.staffs.data.map(
            (staff) =>
              (staff.user_id == this.filterStaff ||
                staff.branch_id == this.filterBranch) &&
              temp.push(staff)
          );
          console.log(temp);
          this.staffs.data = temp;
          console.log(this.staffs);
        }

        return true;
      } catch (e) {
        console.log("Error");
        this.searching = false;
        return false;
      }
    },

    onDropDown(i) {
      if (this.dropdownOpen.isOpen == false) {
        this.dropdownOpen.isOpen = true;
        this.dropdownOpen.index = i;

        return;
      }

      this.dropdownOpen.isOpen = false;
      this.dropdownOpen.index = null;
    },

    showConfirmModal(staff, type) {
      this.selectedStaff = staff;
      this.modalType = type;
      this.isConfirmModalVisible = true;
    },
    closeConfirmModal() {
      this.selectedStaff = null;
      this.isConfirmModalVisible = false;
    },

    confirmRequest(req) {
      console.log("User has confirmed", req);
      this.userHasConfirmed = req;

      if (this.userHasConfirmed == "yes") {
        if (this.modalType === "deactivate") {
          this.updateStatus();
        }

        if (this.modalType === "password") {
          this.resetPassword();
        }
      }
    },

    async updateStatus() {
      // Submit the form
      this.isLoading = true;

      try {
        await axios.patch(`/staff/update/status?staff_id=${this.selectedStaff.id}`);

        this.isLoading = false;

        this.loadData(1);
        this.closeConfirmModal();

        return true;
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },

    async resetPassword() {
      // Submit the form
      this.isLoading = true;

      try {
        await axios.patch(`/staff/update/password?staff_id=${this.selectedStaff.id}`);

        this.isLoading = false;

        this.loadData(1);
        this.closeConfirmModal();

        Swal.fire({
          icon: "success",
          title: "Password Successfully Rest",
        });

        return true;
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },

    getMessage() {
      if (!this.selectedStaff) {
        return "";
      }

      if (this.modalType === "deactivate") {
        if (!this.selectedStaff.status) {
          return `Are you sure you want to activate ${this.selectedStaff.first_name} ${this.selectedStaff.last_name}'s account`;
        }

        return `Are you sure you want to deactivate ${this.selectedStaff.first_name} ${this.selectedStaff.last_name}'s account`;
      } else if (this.modalType === "password") {
        return `Are you sure you want to reset ${this.selectedStaff.first_name} ${this.selectedStaff.last_name}'s password`;
      }
    },
  },
};
</script>
